import { graphql } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import FlexibleContent from '~/components/FlexibleContent';
import SEO from '~/components/Global/SEO';
import HomeHero from '~/components/Hero/HomeHero';
import Layout from '~/components/Layout';
import { HomepageQuery } from '../graphql-types';

interface HomepageProps {
  data: HomepageQuery;
}

const Homepage: FunctionComponent<HomepageProps> = ({ data }): ReactElement => {
  const {
    homePage: { homepageHero },
    layouts,
  } = data.wpPage;
  return (
    <Layout>
      <SEO title="Home" />

      <main>
        <HomeHero hero={homepageHero} />
        <FlexibleContent pageName="home" layouts={layouts} />
      </main>
    </Layout>
  );
};

export default Homepage;

export const query = graphql`
  query Homepage {
    wpPage(isFrontPage: { eq: true }) {
      homePage {
        homepageHero {
          heading
          content
          button {
            title
            url
          }
          logo {
            image {
              ...Image
            }
          }
          image {
            ...Image
          }
        }
      }

      layouts {
        layouts {
          ... on WpPage_Layouts_Layouts_TwoColImageText {
            subheading
            heading
            headingWidth
            content
            contentWidth
            contentAlignment
            image {
              ...Image
            }
            imagePosition
            # alertNotice
            # noticeContent
            backgroundColour
            contentLink
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_UseCasesGrid {
            subheading
            heading
            content
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_FullWidthFeatureBoxesTwoCol {
            heading
            featureBox {
              subheading
              heading
              content
              links {
                link {
                  title
                  url
                }
              }
              #image {
              #  ...Image
              #}
              imageType
              animationName
            }
          }
          ... on WpPage_Layouts_Layouts_DecadeOfPurpose {
            heading
            content
            decade {
              year
              heading
              content
            }
            image {
              ...Image
            }
          }
          ... on WpPage_Layouts_Layouts_Cta {
            heading
            content
            button {
              title
              url
            }
            image {
              ...Image
            }
            mobileImage {
              ...Image
            }
            imagePosition
          }
        }
      }
    }
  }
`;
