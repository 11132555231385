import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import Globe from '~/components/Home/Globe';
import Image from '~/components/Image';

interface HomeHeroProps {
  hero: {
    heading: string;
    content: string;
    button: {
      title: string;
      url: string;
    };
    image: string;
    logo: [
      {
        image: string;
      }
    ];
  };
}

const HomeHero: FunctionComponent<HomeHeroProps> = ({ hero }) => {
  return (
    <section className="bg-lgrey overflow-hidden relative">
      <div className="container">
        <div className="flex flex-wrap">
          <div className="md:w-7/12 w-full py-12 md:pr-10 xl:pr-20 md:flex md:items-center md:relative">
            <div className="py-6 md:py-20">
              <h1
                className="max-w-md"
                dangerouslySetInnerHTML={{ __html: hero.heading }}
              />
              <div
                className="max-w-sm-md text-navy mb-6"
                dangerouslySetInnerHTML={{ __html: hero.content }}
              />
              <Link to={hero.button?.url} className="btn">
                {hero.button?.title}
              </Link>
              <div className="grid grid-cols-3 gap-8 mt-8 md:mt-16 md:max-w-sm">
                {hero.logo.map(({ image }, i) => (
                  <div key={`hero-logo-${i}`} className="w-full h-8">
                    <Image
                      data={image}
                      className="h-full object-contain mx-auto"
                      loading="eager"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="globe-main-cont">
              <Globe />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute h-24 bg-lgrey-gradient inset-x-0 bottom-0 globe-gradient md:hidden"></div>
    </section>
  );
};

export default HomeHero;
